<template>
  <TrackTestAdmin

  />
</template>

<script>
  import TrackTestAdmin from "./TrackTestAdmin/TrackTestAdmin";

  export default {
    name: "TrackTest",
    components: {
      TrackTestAdmin,
    },

    data() {
      return {
      }
    },

    methods: {

    }
  }
</script>

<style scoped>

</style>
