<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
              class="clear-992"
              :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <UserInfo/>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.dashboard"
                             class="detail-page__head-back"
                             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['common_goToDashboard'])"></div>
                  <LinkBack
                          :value="$t('common_goToDashboard.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['trackTest_trackTest'])"></div>
              {{$t('trackTest_trackTest.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--66 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['trackTest_type'])"></div>
                <DefaultSelect
                    :options="options"
                    :label="$t('trackTest_type.localization_value.value')"
                    :optionsLabel="'name'"
                    :otherValue="'translation'"
                    :selected="type"
                    v-bind:class="{'ui-no-valid': validationType}"
                    @change="changeType"
                />
              </div>
            </div>
            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--66 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['trackTest_id'])"></div>
                <DefaultInput
                    :label="$t('trackTest_id.localization_value.value')"
                    v-bind:class="{'ui-no-valid': validationId}"
                    v-model="id"
                />
              </div>
            </div>
            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--66 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['trackTest_send'])"></div>
                <MainButton
                    :value="$t('trackTest_send.localization_value.value')"
                    @click.native="trackTest()"
                    v-bind:class="{'disabled-btn' : $store.getters.getTrackTestBtn}"
                />
              </div>
            </div>

            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--66 custom-col--md-100">
                <div
                    class="color-red mt-1"
                    v-for="(item, index) in this.errors"
                    :key="index"
                >
                  {{item}}
                </div>
              </div>
            </div>

          </div>
        </template>
        <template slot="footer">
          <div class="order-create__footer"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_cancel'])"></div>
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.dashboard"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "./components/UserInfo/UserInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import {TRACK_TEST_TYPES_ARRAY} from "../../../../../../staticData/staticVariables";

  export default {
    name: "TrackTestAdmin",

    components: {
      MainButton,
      DefaultSelect,
      DefaultInput,
      CardLeftBlock,
      UserInfo,
      CardRightBlock,
      LinkBack,
    },

    created() {
      this.$store.dispatch('getTrackTestCreate').then(response => {
        let respData = this.getRespData(response)

        this.options = TRACK_TEST_TYPES_ARRAY.filter(item => {
          return Object.keys(respData).findIndex(findItem => {
            return findItem === item.value
          }) !== -1
        })

        // this.options = Object.keys(respData).map(item => {
        //   return {
        //     name: respData[item],
        //     value: item,
        //   }
        // })
      })
    },

    data() {
      return {
        options: [],
        type: null,
        id: '',
        validationType: false,
        validationId: false,

        errors: [],
      }
    },

    methods: {
      changeType(val) {
        this.type = val
      },

      trackTest() {
        this.validationType = false
        this.validationId = false
        this.errors = []

        if (!this.type) {
          this.validationType = true
        }
        if (!this.id) {
          this.validationId = true
        }

        if (this.validationType || this.validationId) {
          return
        }

        let data = {
          order_type: this.type.value,
          order_id: this.id,
        }

        this.$store.dispatch('getTrackTestResult', data).then(response => {
          let respData = this.getRespData(response)
          console.log(respData);

          this.errors = respData.detail
        })
      }
    },
  }
</script>

<style scoped>

</style>
